import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

import iconInstagram from '../assets/images/instagram.svg'

export default function Footer() {
	return (
		<footer className="page-footer">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-auto">
						<a className="follow follow__instagram" href="https://www.instagram.com/sam.lauren.official/">
							Spread love on insta
							<img className="icon icon--48" src={iconInstagram} alt="Instagram" />
						</a>
					</div>
				</div>
			</div>
			<div className="container-fluid justify-content-end footer-links">
				<ul className="nav justify-content-end">
					<li className="nav-item">
						{/* <a className="nav-link" href="#">Impressum</a> */}
						<Link to="/impressum" className="nav-link">Impressum</Link>
					</li>
					<li className="nav-item">
						{/* <a class="nav-link" href="#">Datenschutz</a> */}
						<Link to="/datenschutz" className="nav-link">Datenschutz</Link>
					</li>
				</ul>
			</div>
			<div className="ninu bg-dark">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col text-center">
							<h6>made with <FontAwesomeIcon icon={faHeart} /> by ninu</h6>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}