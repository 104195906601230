import React from 'react'

import SectionText from '../components/SectionText'

class Imprint extends React.Component {
	render() {
		return (
			<SectionText name="imprint">
				<h1>Impressum</h1>
				<p><strong>Verantwortlich für den Inhalt der Seiten</strong></p>
				<p>Laura Schüler<br />
					PEAX-ID 959.2112.4841.64<br />
					Postfach 1207<br />
					6341 Baar<br />
					<a className="anchor anchor--email" href="mailto:hello@sam-lauren.com" title="E-Mail">hello@sam-lauren.com</a></p>
				<p>Meine Wohnadresse für den Zustand von Paketen gebe ich euch gerne auf Anfrage.</p>
				<h2>Disclaimer</h2>
				<p>Alle Texte und Links wurden sorgfältig geprüft und werden laufend aktualisiert. Wir sind bemüht,
					richtige und vollständige Informationen auf dieser Website bereitzustellen, übernehmen aber
					keinerlei Verantwortung, Garantien oder Haftung dafür, dass die durch diese Website bereitgestellten
					Informationen, richtig, vollständig oder aktuell sind. Wir behalten uns das Recht vor, jederzeit und
					ohne Vorankündigung die Informationen auf dieser Website zu ändern und verpflichten uns auch nicht,
					die enthaltenen Informationen zu aktualisieren. Alle Links zu externen Anbietern wurden zum Zeitpunkt
					ihrer Aufnahme auf ihre Richtigkeit überprüft, dennoch haften wir nicht für Inhalte und Verfügbarkeit
					von Websites, die mittels Hyperlinks zu erreichen sind. Für illegale, fehlerhafte oder unvollständige
					Inhalte und insbesondere für Schäden, die durch Inhalte verknüpfter Seiten entstehen, haftet allein der
					Anbieter der Seite, auf welche verwiesen wurde. Dabei ist es gleichgültig, ob der Schaden direkter,
					indirekter oder finanzieller Natur ist oder ein sonstiger Schaden vorliegt, der sich aus Datenverlust,
					Nutzungsausfall oder anderen Gründen aller Art ergeben könnte.</p>
			</SectionText>
		)
	}
}

export default Imprint