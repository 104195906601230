import React from 'react'
import PropTypes from 'prop-types'

class Section extends React.Component {
	render() {
		return (
			<section className={ `section section--${ this.props.type } section--${ this.props.name }` }>
				{ this.props.children }
			</section>
		)
	}
}

Section.propTypes = {
	name: PropTypes.string,
	type: PropTypes.string.isRequired
}

export default Section