import React, { useState } from 'react'
import { Link as DOMLink, useLocation } from 'react-router-dom'
import Scroll from 'react-scroll'
const Link = Scroll.Link


import logo from '../assets/images/logo.svg'

function logoLink() {
	if (location === '/impressum' || location === '/datenschutz') {
		return <DOMLink to="/" className="navbar-brand"><img src={logo} className="logo__image" alt="logo" /></DOMLink>
	}
	return <Link
		to="home"
		spy={true}
		smooth={true}
		hashSpy={true}
		duration={500}
		className='navbar-brand'
	>
		<img src={logo} className="logo__image" alt="logo" />
	</Link>
}

function getLink(link, location = null) {
	if (location === '/impressum' || location === '/datenschutz') {
		return <DOMLink to={ `/#${ link.target }` } className="nav-link">{ link.text }</DOMLink>
	}
	return <Link
		to={ link.target }
		spy={true}
		smooth={true}
		hashSpy={true}
		offset={-60}
		duration={500}
		className='nav-link'
		activeClass='active'
		onClick={ link.onClick }
	>
		{ link.text }
	</Link>
}

export default function Header() {
	const location = useLocation()

	const [ isNavCollapsed, setIsNavCollapsed ] = useState(
		true
	)

	const linkAbout = {
		text: 'Über mich',
		target: 'about',
		onClick: () => setIsNavCollapsed(!isNavCollapsed)
	}

	const linkFeed = {
		text: 'Feed',
		target: 'feed',
		onClick: () => setIsNavCollapsed(!isNavCollapsed)
	}

	const linkContact = {
		text: 'Kontakt',
		target: 'contact',
		onClick: () => setIsNavCollapsed(!isNavCollapsed)
	}

	return (
		<header className="page-header">
			<nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-primary-dark">
				<div className="container-fluid justify-content-end">
					{/* <DOMLink to="/" className="navbar-brand"><img src={logo} className="logo__image" alt="logo" /></DOMLink> */}
					{ logoLink() }
					<button className="navbar-toggler" type="button" data-toggle="collapse"
						data-target="#navbarSupportedContent" aria-controls="navbarsExample09"
						aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation"
						onClick={ () => setIsNavCollapsed(!isNavCollapsed) }>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div
						className={`${ isNavCollapsed ? 'collapse' : '' } navbar-collapse flex-grow-0 justify-content-end`}
						id="navbarSupportedContent">
						<ul className="navbar-nav me-auto mb-2 mb-lg-0 text-center">
							<li className="nav-item">
								{ getLink(linkAbout, location.pathname) }
							</li>
							<li className="nav-item">
								{ getLink(linkFeed, location.pathname) }
							</li>
							<li className="nav-item">
								{ getLink(linkContact, location.pathname) }
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</header>
	)
}