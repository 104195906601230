/* eslint-disable max-len */
import React from 'react'
import { Element, Link } from 'react-scroll'
import Instafeed from 'instafeed.js'

import imageIntro from '../assets/images/mikolaj-DCzpr09cTXY-unsplash.jpg'
import image1 from '../assets/images/patrick-fore-0gkw_9fy0eQ-unsplash.jpg'
import image2 from '../assets/images/janko-ferlic-sfL_QOnmy00-unsplash.jpg'
import image3 from '../assets/images/lucas-george-wendt-8CozIYUUJJ4-unsplash.jpg'
import iconDown from '../assets/images/down.svg'

import SectionImage from '../components/SectionImage'
import SectionText from '../components/SectionText'
import Quote from '../components/Quote'


class Home extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isStupidFeedLoaded: false
		}
	}

	render() {
		const feed = new Instafeed({
			accessToken: 'IGQVJYT1c2WGR6NVh6amlSUWtxSW1CZAkpQSjc0NEc0UENGNDJWYVRxQkRzZAndIakx5akVLTUUwN1FndVJpSjlVRG5UYXBIdFpBYXJrMUtmLUFBSkRRaEZAGT21Ma1VPTVJyLTN6QjdldzllM3hmVDBlQgZDZD',
			template: '<a href="{{link}}" target="_blank" id="{{id}}" class="instafeed__link"><img src="{{image}}"  class="instafeed__image" /></a>',
			limit: 9
		})
		setTimeout(() => {
			if (!this.state.isStupidFeedLoaded) {
				feed.run()
				this.setState({ isStupidFeedLoaded: true })
			}
		}, 100)

		return (
			<>
				<div className="intro">
					<SectionImage
						name="quote1"
						image={ imageIntro }
						// eslint-disable-next-line max-len
						imageSource={ <span>Photo by <a href="https://unsplash.com/@qmikola?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Mikołaj</a> on <a href="https://unsplash.com/s/photos/book?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></span> }
					/>
					<Link
						to="about"
						smooth={true}
						hashSpy={true}
						offset={-60}
						duration={500}
						className='downlink'
					>
						<img src={ iconDown } className="downlink__icon" />
					</Link>
				</div>
				<Element name="about" id="about">
					<SectionText
						name="about"
					>
						<h1 className="text-center">Wer bin ich?</h1>
						<p>Ich freue mich, dich auf meiner Webseite begrüssen zu dürfen! Mein Name ist Sam, ich bin 24 Jahre alt und lebe mit meinen Männern - den kleinen Chinchilla-Bubis Blaze, Shadow und Dusty - in der schönen Schweiz. Ich arbeite als Software Entwicklerin und vertiefe momentan mein Wissen in einem berufsbegleitenden Studium.</p>
						<p>Obwohl ich als Kind keinem Buch auch nur die kleinste Aufmerksam geschenkt habe (nein, nicht mal Harry Potter wollte ich lesen, das habe ich zum Glück vor ein paar Jahren nachgeholt), bin ich heute leidenschaftliche Leserin. Sei es ein spannender Psychothriller, ein New Adult Roman oder ein Fantasy Buch - ich gebe jedem Genre und Buch eine Chance.</p>
						<p>Mit meiner blühenden (Thriller-) Fantasy, habe ich auch schon einige Seiten zu Papier gebracht. Mein grosser Traum wäre es, irgendwann mein eigenes Buch in Händen halten zu dürfen.</p>
					</SectionText>
					<SectionImage
						name="quote1"
						image={ image1 }
						imageSource={ <span>Photo by <a href="https://unsplash.com/@itfeelslikefilm?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Janko Ferlič</a> on <a href="https://unsplash.com/s/photos/books?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></span> }
					>
						<Quote
							source="Sam Lauren"
						>
							Ich denke, dass wir alle, tief in unserem Inneren, zu Unaussprechlichem fähig sind. Und manchmal drücken wir unsere Gefühle durch Taten aus, die der Boshaftigkeit gleichen.
						</Quote>
					</SectionImage>
				</Element>
				<Element name="feed" id="feed">
					<SectionText
						name="feed"
					>
						<h2 className="text-center">Feed</h2>
						<div className="feedContainer">
							<div id="instafeed"></div>
						</div>
					</SectionText>
					<SectionImage
						name="quote2"
						image={ image2 }
						// eslint-disable-next-line max-len
						imageSource={ <span>Photo by <a href="https://unsplash.com/@patrickian4?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Patrick Fore</a> on <a href="https://unsplash.com/s/photos/typewriter?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></span>}
					>
						<Quote
							source="Sebastian Fitzek - der Augenjäger"
						>
							Es liegt in der Natur des Menschen, bis in den Tod hinein an die Kraft des Guten zu glauben, selbst dann, wenn er dem Bösen in seiner reinsten Form begegnet.
						</Quote>
					</SectionImage>
				</Element>
				<Element name="contact" id="contact">
					<SectionText
						name="contact"
					>
						<h2 className="text-center">Kontakt</h2>
						<p>Schreib mir doch ne Nachricht an <a className="anchor anchor--email" href="mailto:hello@sam-lauren.com" title="E-Mail">hello@sam-lauren.com</a> oder auf Insta <a className="anchor anchor--external" href="https://www.instagram.com/sam.lauren.official/" title="Instagram">@sam.lauren.official</a></p>
					</SectionText>
					<SectionImage
						name="quote3"
						image={ image3 }
						// eslint-disable-next-line max-len
						imageSource={ <span>Photo by <a href="https://unsplash.com/@lucasgwendt?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Lucas George Wendt</a> on <a href="https://unsplash.com/s/photos/book?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></span>	}
					></SectionImage>
				</Element>
			</>
		)
	}
}

export default Home