import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollTo() {
	const location = useLocation()
	let offsetY = 0

	useEffect(() => {
		if (location.hash) {
			const target = document.querySelector(location.hash)
			offsetY = target.offsetTop
		}

		window.scrollTo(0, offsetY)
	}, [ location.pathname ])

	return null
}