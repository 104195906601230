import React from 'react'
import PropTypes from 'prop-types'

import Section from './Section'

class SectionImage extends React.Component {
	render() {
		return (
			<Section name={ this.props.name } type="image">
				<figure className="bigImage">
					<img className="bigImage__image" src={ this.props.image } alt="" />
					<figcaption className="bigImage__source">
						{ this.props.imageSource }
					</figcaption>
				</figure>
				{ this.props.children }
			</Section>
		)
	}
}

SectionImage.propTypes = {
	name: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
	imageSource: PropTypes.object.isRequired,
	author: PropTypes.string,
	text: PropTypes.string
}

export default SectionImage