import {
	BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Header from './components/Header'
import Footer from './components/Footer'
import ScrollTo from './components/ScrollTo'

import Home from './pages/Home'
import Imprint from './pages/Imprint'
import Privacy from './pages/Privacy'

import './App.scss'

function App() {
	return (
		<div className="eminem" name="home" id="home">
			<Router>
				<ScrollTo />
				<Header />
				<main>
					<Switch>
						<Route exact path="/">
							<Home />
						</Route>
						<Route path="/impressum">
							<Imprint />
						</Route>
						<Route path="/datenschutz">
							<Privacy />
						</Route>
					</Switch>
				</main>
				<Footer />
			</Router>
		</div>
	)
}

export default App