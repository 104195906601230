import React from 'react'
import PropTypes from 'prop-types'

class Quote extends React.Component {
	render() {
		return (
			<figure className="blockquote-container">
				<blockquote className="blockquote">
					<p>{ this.props.children }</p>
				</blockquote>
				<figcaption className="blockquote-footer text-end">
					<cite title="Source Title">{ this.props.source }</cite>
				</figcaption>
			</figure>
		)
	}
}

Quote.propTypes = {
	source: PropTypes.string
}

export default Quote