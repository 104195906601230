import React from 'react'

import SectionText from '../components/SectionText'

class Privacy extends React.Component {
	render() {
		return (
			<SectionText name="privacy">
				<h1>Datenschutz</h1>
				<h2>Website</h2>
				<p>Unsere Webseite ist auf vielfältige Weise mit Funktionen und Systemen Dritter vernetzt. Im Folgenden beschreiben
					wir diejenigen Dienste, welche in unserem Auftrag auf der Webseite erhobene Personendaten verarbeiten.</p>

				<h2>Cookies</h2>
				<p>Cookies sind das technische Hilfsmittel für die obenstehenden Datenerhebungen von Drittanbietern. Cookies sind
					ungefährliche Textdateien, die Ihr Browser automatisch auf Ihrem Computer speichert, wenn Sie unsere Internetseite
					besuchen. Sie ermöglichen die Identifizierung eines Besuchers innerhalb einer vordefinierten Zeitspanne.</p>
				<p>Die meisten Internet-Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch jederzeit so
					konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden. Unsere Webseite lässt sich grundsätzlich
					auch ohne die Annahme von Cookies nutzen, allerdings ist dann die Funktionalität dieser Webseite deutlich
					eingeschränkt.</p>

				<h2>Server-Aufzeichnungen</h2>
				<p>Die Server-Infrastruktur erfasst bei jedem Aufruf unserer Webseite eine Reihe von allgemeinen Daten zum Zugriff,
					welche in sog. Logfiles gespeichert werden. Erfasst werden der Name der abgerufenen Webseite, URL, Datum und
					Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp und dessen Version, das
					Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite) sowie die IP-Adresse des Nutzers.
					Diese Daten werden benötigt, um die Funktionsfähigkeit und Sicherheit unserer Infrastruktur zu überprüfen und zu
					gewährleisten.</p>

				<h2>Rechte von betroffenen Personen</h2>
				<p>Sie haben das Recht, jederzeit Ihre Datenschutzrechte geltend zu machen. Kontaktieren Sie unseren
					Datenschutzbeauftragten, sofern Sie von einem der folgenden Rechte Gebrauch machen möchten:</p>
				<p>Auskunftsrecht: Sie können jederzeit Auskunft darüber verlangen, ob und welche Daten wir über Sie gespeichert
					haben und zu welchem Zweck wir sie verwenden.</p>
				<p>Recht auf Vergessen werden: Werden die Daten zu Ihrer Person nicht mehr zum ursprünglichen Verwendungszweck
					benötigt, so werden diese Daten vernichtet.</p>
				<p>Recht auf Löschung: Sie können jederzeit von uns verlangen, Ihre Personendaten zu löschen, sofern keine andere
					gesetzliche Aufbewahrungspflicht besteht.</p>
				<p>Recht auf Widerspruch (Opt-out): Sie können jederzeit von uns verlangen, dass wir die Verarbeitung Ihrer
					persönlichen Daten einstellen.</p>

				<h2>Aufbewahrungsdauer</h2>
				<p>Wir bewahren Ihre Daten nur so lange auf, wie es rechtlich oder entsprechend dem Verarbeitungszweck notwendig
					ist. Bearbeiten wir Ihre Personendaten aufgrund einer Vertragsbeziehung, so bleiben diese Daten so lange
					gespeichert, wie dies gesetzliche und vertragliche Aufbewahrungspflichten vorsehen.</p>

				<h2>Datensicherheit</h2>
				<p>Wir schützen Ihre personenbezogenen Daten durch die üblichen organisatorischen und technischen
					Sicherheitsmassnahmen. Die Massnahmen prüfen wir laufend und ändern sie falls nötig, um mögliche Risiken
					einzudämmen und um eine unbefugte Nutzung zu verhindern. Wir können aber nicht garantieren, dass Daten nicht
					verloren gehen, missbraucht oder geändert werden. In solchen Fällen ergreifen wir die gesetzlich geforderten
					Massnahmen und informieren Betroffene und Behörden umgehend und transparent.</p>

				<h2>Ansprechpartner</h2>
				<p>Wenn Sie Fragen zum Datenschutz haben, Auskünfte verlangen oder die Löschung Ihrer Daten beantragen möchten,
					wenden Sie sich bitte an hello@sam-lauren.com.</p>

				<h2>6. Änderungen der Datenschutzbestimmungen</h2>
				<p>Durch die Weiterentwicklung der rechtlichen Rahmenbedingungen sowie aufgrund des technologischen Fortschrittes
					kann es notwendig werden, diese Datenschutzbestimmungen zu ändern. Die jeweils aktuellen Datenschutzbestimmungen
					können jederzeit auf unserer Webseite von Ihnen abgerufen werden.</p>
			</SectionText>
		)
	}
}

export default Privacy