import React from 'react'
import PropTypes from 'prop-types'

import Section from './Section'

class SectionText extends React.Component {
	render() {
		return (
			<Section name={ this.props.name } type="text">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col col-md-12 col-lg-10 col-xl-8">
							{ this.props.children }
						</div>
					</div>
				</div>
			</Section>
		)
	}
}

SectionText.propTypes = {
	name: PropTypes.string.isRequired
}

export default SectionText